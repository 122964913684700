import { IAdapterCMS } from '@simpl/cms/types'

export default class VoidAdapter implements IAdapterCMS {
  runId: string | number | null
  moduleId: string | number | null
  currentObjective: string | number | null

  constructor () {
    this.moduleId = null
    this.runId = null
    this.currentObjective = null
  }

  async init (runId: string | number | null, moduleId: string | number | null) {
    this.runId = runId
    this.moduleId = moduleId
  }

  read<T extends any> (key: string, defaultValue?: T): T {
    return defaultValue as T
  }

  write (key: string, data: any): void {
  }

  completionStatus (): number {
    return 0
  }

  setCurrentObjective (objective: string | number) {
    this.currentObjective = objective
  }

  getCurrentObjective (): string | number | null {
    return this.currentObjective
  }

  isObjectiveCompleted (objective: string | number): boolean {
    return false
  }

  setCompleted (objective: string | number): void {
  }
}
